import React from 'react';
import styles from './Loader.module.css';
import { classNames } from '../utils/classNames';

export function Loader({
  dark = false,
  size,
}: {
  dark?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}) {
  const ballCount = 3;
  return (
    <div className="flex">
      {Array.from(Array(ballCount)).map((_, index) => (
        <div
          key={index}
          className={classNames(
            styles.loaderBall,
            dark ? styles.loaderBallDark : styles.loaderBallLight,
            (() => {
              if (size === 'xs') return 'w-1 h-1';
              if (size === 'md') return 'w-3 h-3';
              if (size === 'lg') return 'w-4 h-4';
              return 'w-2 h-2';
            })(),
          )}
          style={{
            animationDelay: `${index / ballCount}s`,
          }}
        />
      ))}
    </div>
  );
}
