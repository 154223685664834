import React from 'react';
import { Loader } from './Loader';
import styles from './Button.module.css';

export type ButtonVariant = 'primary' | 'secondary';

export type ButtonProps = {
  className?: string;
  variant?: ButtonVariant;
  disabled?: boolean;
  isLoading?: boolean;
  loaderSize?: 'xs' | 'sm' | 'md' | 'lg';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({
  children,
  className = '',
  variant = 'primary',
  disabled,
  isLoading,
  loaderSize,
  ...props
}: ButtonProps) {
  const buttonVariants = {
    primary: styles.buttonPrimary,
    secondary: styles.buttonSecondary,
  };

  return (
    <button
      className={`${styles.buttonBase} ${buttonVariants[variant]} ${className}`}
      disabled={disabled || isLoading}
      aria-disabled={disabled || isLoading}
      {...props}
    >
      <div
        className="flex justify-center space-x-2"
        style={{
          opacity: isLoading ? 0 : 1,
        }}
      >
        {children}
      </div>

      {isLoading && (
        <div className={styles.buttonLoader}>
          <Loader dark={variant === 'secondary'} size={loaderSize} />
        </div>
      )}
    </button>
  );
}
